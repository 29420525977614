import React from "react";
import {Theme} from "@mui/material";
import {createStyles, makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => createStyles({
    legalNoticeContainer: {
        display: "flex",
        flexDirection: "column",
        width: "calc(100% - 40px)",
        maxWidth: "1040px",
        padding: "0 20px 20px",
        margin: "0 auto auto"
    },
    legalNoticeHeadline: {
        width: "100%",
        fontSize: "38px",
        fontWeight: 700,
        lineHeight: "52px",
        textAlign: "center",
        color: "#023553",
        margin: "40px auto",
        [theme.breakpoints.down(950)]: {
            fontSize: "30px",
            lineHeight: "40px"
        },
        [theme.breakpoints.down(500)]: {
            fontSize: "24px",
            lineHeight: "30px"
        }
    },
}));

export default function LegalNotice() {

    const classes = useStyles();

    return (
        <div className={classes.legalNoticeContainer}>
            <div className={classes.legalNoticeHeadline}>Impressum</div>
            <h2 style={{color: "rgb(2, 53, 83)"}}>Angaben gemäß § 5 TMG</h2>
            <p>Universitätssegelclub Oldenburg e. V.<br/>
                Rauhehorst 193<br/>
                26127 Oldenburg<br/>
                <br/>
                Vereinsregister: VR 1463<br/>
                Registergericht: Amtsgericht Oldenburg</p>
            <h2 style={{color: "rgb(2, 53, 83)"}}>Vertreten durch:</h2>
            <p>1. Vorsitzender: Eckard Jürges<br/>
                2. Vorsitzender: Oliver Lettau<br/>
                Kassenwart: Dietmar Tüchsen<br/>
                Schriftführer: Oliver Steinert</p>
            <h2 style={{color: "rgb(2, 53, 83)"}}>Kontakt:</h2>
            <p>E-Mail: info@usco-online.de</p>
            <h2 style={{color: "rgb(2, 53, 83)"}}>Verbraucherstreitbeilegung/Universalschlichtungsstelle:</h2>
            <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
                Verbraucherschlichtungsstelle teilzunehmen.</p>
            <h2 style={{color: "rgb(2, 53, 83)"}}>Haftung für Inhalte:</h2>
            <p>Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den
                allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
                verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu
                forschen, die auf eine rechtswidrige Tätigkeit hinweisen.</p>
            <p>Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen
                bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis
                einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden
                wir diese Inhalte umgehend entfernen.</p>
            <h2 style={{color: "rgb(2, 53, 83)"}}>Haftung für Links:</h2>
            <p>Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben.
                Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
                verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die
                verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
                Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.</p>
            <p>Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer
                Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links
                umgehend entfernen.</p>
            <h2 style={{color: "rgb(2, 53, 83)"}}>Urheberrecht:</h2>
            <p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen
                Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
                Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
                Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.</p>
            <p>Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte
                Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem
                auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei
                Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.</p>
            <h2 style={{color: "rgb(2, 53, 83)"}}>Datenschutz:</h2>
            <p>Die per Formular oder E-Mail abgesendeten Daten sind verschlüsselt und müssen zur Bearbeitung Ihrer
                Anfragen und Hinweise gespeichert werden. Die von geforderten Angaben sind freiwillig und dienen der
                Informationsverarbeitung in unserem Hause. Wir versichern an dieser Stelle, dass persönliche Daten
                ausschließlich gemäß dem Merkblatt&nbsp;
                <a rel="nofollow noreferrer" target="_blank" href={"static/dsgvo/Datenschutz-Merkblatt_USCO_F.pdf"}>
                    „Informationspflichten nach Artikel 12, 13 und 14 EU-Datenschutz-Grundverordnung (DS-GVO)“</a>
                &nbsp;verwendet bzw. verarbeitet und Dritten nicht zugänglich gemacht werden.</p>
            <h2 style={{color: "rgb(2, 53, 83)"}}>Quelle:</h2>
            <p>e-recht24.de</p>
            <h2 style={{color: "rgb(2, 53, 83)"}}>Bildnachweise:</h2>
            <p>Icons made by <a rel="nofollow noreferrer" target="_blank" href="https://www.freepik.com" title="Freepik">
                Freepik</a> from <a rel="nofollow noreferrer" target="_blank" href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></p>
        </div>
    );
}
