import React from "react";
import PageHeader from "../../components/PageHeader";
import PageContent from "../../components/PageContent";
import EditableSite from "../../components/site/EditableSite";
import * as TYPES from "../../constants/siteTypes";
import SiteImage from "../../components/site/SiteImage";
import {BeforeSectionExtension, BehindSectionExtension} from "../../components/site/EditableSection";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material";
import ImageWithTextContainer from "../../components/ImageWithTextContainer";
import './CourseStyles.css'
import TeaserCarousel, {TeaserData} from "../../components/teaser/TeaserCarousel";
import * as ROUTES from "../../constants/routes";
import {useNavigate} from "react-router";

const useStyles = makeStyles((theme: Theme) => createStyles({
    adultSailingCourseContainer: {
        paddingTop: "20px"
    },
    adultSailingCourseTeaserContainer: {
        margin: "auto",
        maxWidth: "1280px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "18px 43px 40px 43px",
        [theme.breakpoints.down(1280)]: {
            width: "100%",
            padding: "18px 0 5px 0"
        }
    },
    adultSailingCourseTeaserHeadline: {
        margin: "20px auto 0",
        color: "#023553",
        textAlign: "center"
    },
}));

export default function AdultSailingCourses() {

    const navigate = useNavigate();
    const classes = useStyles();

    const teaserData: TeaserData[] = [
        {
            image: "teaser/teaser_adult-sailing-courses.jpg",
            title: "Der richtige Kurs zum Schein",
            content: "Ob Segeln, Motorboot fahren oder Funken: manövriere deinen Mauszeiger jetzt mit nur wenigen Klicks zur Kursanfrage.",
            actionTitle: "Zur Kursanfrage",
            action: () => {
                navigate(ROUTES.SAILING_COURSE_REGISTER);
            }
        },
        {
            image: "teaser/home_teaser_children.jpeg",
            title: "Segeln für Kids",
            content: "Ob Landratte oder erfahrener Jungmatrose: Kids und Jugendliche mit Lust aufs Segeln können bei uns Erfahrungen sammeln und in See stechen.",
            actionTitle: "Zum Kindersegeln",
            action: () => {
                navigate(ROUTES.CHILD_SAILING_COURSES);
            }
        },
        {
            image: "teaser/buoy-5211255.jpg",
            title: "Ablegen vor der Haustür",
            content: "In Oldenburg und Bad Zwischenahn segeln Mitglieder mit der Bootsflotte des Clubs unabhängig von der Tiede.",
            actionTitle: "Zu Flotte und Reviere",
            action: () => {
                navigate(ROUTES.BOATS_AND_TERRITORIES);
            }
        }
    ];

    return (
        <>
            <PageHeader headerImage="header/adult-sailing-courses.jpg">
                Segel- und Motorbootausbildung und -scheine
            </PageHeader>
            <PageContent className={classes.adultSailingCourseContainer}>
                <EditableSite siteType={TYPES.ADULT_SAILING_COURSES}>
                    <BeforeSectionExtension style={{ height: "48px" }} position={1}>
                        <SiteImage image="site-icon/student_blue.svg"
                                   width="64px"
                                   height="64px"
                                   style={{ marginTop: "-7px" }}/>
                    </BeforeSectionExtension>
                    <BehindSectionExtension position={3}>
                        <ImageWithTextContainer
                            style={{marginBottom: "50px"}}
                            image="breaker/pexels-yaroslav-shuraev-8917660.jpg"
                            headline=""
                            content="Wir empfehlen, erst den Sportbootführerschein See zu erwerben und im Anschluss im Sommer den Sportbootführerschein Binnen. Das ist die kostengünstigste und effektivste Möglichkeit, diese beiden 'Grundlagenscheine' zu erwerben."
                            contentStyle={{marginBottom: "-50px"}}
                        />
                    </BehindSectionExtension>
                </EditableSite>
            </PageContent>
            <div className={classes.adultSailingCourseTeaserContainer}>
                <TeaserCarousel data={teaserData}/>
            </div>
        </>
    );
}
