import React, {PropsWithChildren, useEffect, useState} from "react";
import {MessagePriority, messagePriorityToText, MessageType, messageTypeToText} from "../../model/news/IMessage";
import {createStyles, makeStyles} from "@mui/styles";
import FilterDropdown from "../FilterDropdown";
import FilterSearchInput from "../FilterSearchInput";
import {MenuItem, Theme} from "@mui/material";

const useStyles = makeStyles((theme: Theme) => createStyles({
    messagesFilterContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        backgroundColor: "#ffffff",
        margin: "0 40px 40px",
        padding: "20px",
        width: "calc(100% - 80px)",
        [theme.breakpoints.down(950)]: {
            flexDirection: "column",
            padding: "20px",
            margin: "0 20px 20px",
            width: "calc(100% - 80px)",
        },
        [theme.breakpoints.down(415)]: {
            margin: "0 0 20px",
            width: "calc(100% - 40px)"
        }
    },
    messagesFilterHeadline: {
        color: "#023553",
        fontWeight: 500,
        lineHeight: "56px",
        marginRight: "12px",
        [theme.breakpoints.down(950)]: {
            marginRight: "0"
        }
    },
    messageFilterDropdownInput: {
        marginRight: "12px",
        [theme.breakpoints.down(950)]: {
            marginRight: "0",
            marginBottom: "10px"
        }
    }
}));

export type MessageFilter = {
    messageType?: MessageType;
    messagePriority?: MessagePriority;
    messageAuthor?: string;
    messageContent?: string;
};

interface MessageListFilterProps extends PropsWithChildren<any> {
    authors: string[];
    extendedFilter?: boolean;

    onUpdateFilter(filter: MessageFilter): void;
}

export default function MessageListFilter(props: MessageListFilterProps) {
    const [filteredMessageType, setFilteredMessageType] = useState<MessageType>();
    const [filteredMessagePriority, setFilteredMessagePriority] = useState<MessagePriority>();
    const [filteredMessageAuthor, setFilteredMessageAuthor] = useState<string>();
    const [searchedMessageContent, setSearchedMessageContent] = useState<string>();
    const [filter, setFilter] = useState<MessageFilter>({});

    const {authors, extendedFilter} = props;

    const classes = useStyles();

    useEffect(() => {
        setFilter({
            messageType: filteredMessageType,
            messageAuthor: filteredMessageAuthor,
            messagePriority: filteredMessagePriority,
            messageContent: searchedMessageContent
        })
    }, [
        filteredMessageType,
        filteredMessageAuthor,
        filteredMessagePriority,
        searchedMessageContent
    ]);

    useEffect(() => {
        props.onUpdateFilter(filter);
    }, [props, filter]);

    return (
        <div className={classes.messagesFilterContainer}>
            <div className={classes.messagesFilterHeadline}>FILTER:</div>
            {extendedFilter && (
                <FilterDropdown id={"message-type-filter"}
                                className={classes.messageFilterDropdownInput}
                                label={"Typ"}
                                defaultValue={""}
                                onChange={(event: any) => {
                                    setFilteredMessageType(event.target.value === "" ?
                                        undefined : event.target.value);
                                }}>
                    <MenuItem value={""}>Keinen Typ</MenuItem>
                    <MenuItem value={MessageType.MESSAGE}>{messageTypeToText(MessageType.MESSAGE)}</MenuItem>
                    <MenuItem
                        value={MessageType.NEWSLETTER}>{messageTypeToText(MessageType.NEWSLETTER)}</MenuItem>
                </FilterDropdown>
            )}
            {extendedFilter && (
                <FilterDropdown id={"message-priority-filter"}
                                className={classes.messageFilterDropdownInput}
                                label={"Priorität"}
                                defaultValue={""}
                                onChange={(event: any) => {
                                    setFilteredMessagePriority(event.target.value === "" ?
                                        undefined : event.target.value);

                                }}>
                    <MenuItem value={""}>Keine Priorität</MenuItem>
                    <MenuItem
                        value={MessagePriority.HERO}>{messagePriorityToText(MessagePriority.HERO)}</MenuItem>
                </FilterDropdown>
            )}
            <FilterDropdown id={"message-author-filter"}
                            className={classes.messageFilterDropdownInput}
                            label={"Autor"}
                            defaultValue={""}
                            onChange={(event: any) => {
                                setFilteredMessageAuthor(event.target.value === "" ?
                                    undefined : event.target.value);
                            }}>
                <MenuItem value={""}>Kein Autor</MenuItem>
                {authors.map((author: string, index: number) => (
                    <MenuItem key={index} value={author}>{author}</MenuItem>
                ))}
            </FilterDropdown>
            <FilterSearchInput id={"message-search-filter"}
                               label={"Suche"}
                               placeholder={"In der Anzeigeliste suchen"}
                               defaultValue={""}
                               onChange={(event: any) => {
                                   setSearchedMessageContent(event.target.value === "" ?
                                       undefined : event.target.value);
                               }} />
        </div>
    );

}
