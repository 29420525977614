import React, {ChangeEvent, PropsWithChildren} from "react";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import MenuItem from "@mui/material/MenuItem";
import {Membership, membershipToText} from "../../../model/member/IMember";
import {BackgroundTextField} from "../../../components/StyledComponents";
import BackgroundDropdown from "../../../components/BackgroundDropdown";
import ContentCheckbox from "../../../components/ContentCheckbox";

const useStyles = makeStyles(() => createStyles({
    registerSection: {
        marginTop: "40px",
        marginBottom: "20px",
        color: "#023553"
    },
}));

export interface RegisterAccountProps extends PropsWithChildren<any> {
    validated: boolean;
    usernameExists: boolean;
    mailAddressExists: boolean;
    username: string;
    membership: Membership;
    mailAddress: string;
    courseMember: boolean;

    onChangeUsername(username: string): void;
    onChangeMembership(membership: Membership): void;
    onChangeMailAddress(mailAddress: string): void;
    onChangeCourseMember(courseMember: boolean): void;
}

export default function RegisterAccount(props: RegisterAccountProps) {
    const {validated, usernameExists, mailAddressExists, username, membership, mailAddress, courseMember,
        onChangeUsername, onChangeMembership, onChangeMailAddress, onChangeCourseMember} = props;
    const classes = useStyles();

    return (
        <>
            <div className={classes.registerSection}>Mitgliedschaft</div>
            <BackgroundTextField required
                                 error={(validated && !username) || usernameExists}
                                 helperText={validated && !username
                                     ? "Der Nutzername fehlt." : usernameExists
                                         ? "Der Nutzername ist bereits vergeben" : null}
                                 variant="outlined"
                                 label="Nutzername"
                                 placeholder="Dein Nutzername"
                                 onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                     onChangeUsername(event.target.value);
                                 }}/>
            <BackgroundDropdown id="register-membership-label"
                                label="Art der Mitgliedschaft"
                                style={{marginBottom: "17px"}}
                                value={membership}
                                nonValue={Membership.NONE}
                                errorMessage="Art der Mitgliedschaft nicht ausgewählt."
                                validated={validated}
                                required={true}
                                onChange={(newValue: any) => {
                                    onChangeMembership(newValue);
                                }}>
                <MenuItem value={Membership.SINGLE}>{membershipToText(Membership.SINGLE)}</MenuItem>
                <MenuItem value={Membership.JUNIOR}>{membershipToText(Membership.JUNIOR)}</MenuItem>
                <MenuItem value={Membership.STUDENT}>{membershipToText(Membership.STUDENT)}</MenuItem>
                <MenuItem value={Membership.FAMILY}>{membershipToText(Membership.FAMILY)}</MenuItem>
            </BackgroundDropdown>
            <BackgroundTextField required
                                 error={(validated && !mailAddress) || mailAddressExists}
                                 helperText={validated && !mailAddress
                                     ? "Die E-Mail-Adresse fehlt." : mailAddressExists
                                         ? "Die E-Mail-Adresse ist bereits vergeben" : null}
                                 variant="outlined"
                                 label="E-Mail-Adresse"
                                 placeholder="Deinen E-Mail-Adresse"
                                 type="email"
                                 onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                     onChangeMailAddress(event.target.value);
                                 }}/>
            <ContentCheckbox checked={courseMember}
                             multiline={true}
                             label={<>Ich habe mich zuvor für die Ausbildung zum Sportboot-führerschein Binnen
                                 bzw. See beim USCO angemeldet und nehme an der Ausbildung teil.</>}
                             onChange={(event: ChangeEvent<HTMLInputElement>)=> {
                                 onChangeCourseMember(event.target.checked);
                             }}/>
        </>
    );
}
