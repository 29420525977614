import React, {useState} from "react";
import PageHeader from "../../../components/PageHeader";
import PageContent from "../../../components/PageContent";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import {Gender, Membership} from "../../../model/member/IMember";
import ContentButton from "../../../components/ContentButton";
import {useNavigate} from "react-router";
import ContentLoadingButton from "../../../components/ContentLoadingButton";
import {RegisterServiceApi} from "../../../api/MemberServiceApi";
import {ApiError} from "../../../model/Errors";
import RegistrationData from "../../../model/member/RegistrationData";
import RegisterAccount from "./RegisterAccount";
import RegisterPersonalData from "./RegisterPersonalData";
import RegisterContact from "./RegisterContact";
import RegisterBankAccount from "./RegisterBankAccount";
import RegisterDirectDebitMandate from "./RegisterDirectDebitMandate";
import RegisterSummery from "./RegisterSummery";
import ContentCheckbox from "../../../components/ContentCheckbox";
import {validateBic, validateIban} from "../../../model/Validator";

const useStyles = makeStyles(() => createStyles({
    registerContainer: {
        display: "flex",
        flexDirection: "column",
        width: "calc(100% - 40px)",
        maxWidth: "500px",
        padding: "20px",
        margin: "auto"
    },
    registerButtonContainer: {
        display: "flex",
        flexDirection: "row",
        marginBottom: "20px"
    },
    registerAbortButton: {
        marginLeft: "auto",
        marginRight: "0"
    },
    registerSaveButton: {
        marginLeft: "10px",
        marginRight: "0"
    },
    registerInfoText: {
        fontSize: "14px",
        marginLeft: "30px",
        marginBottom: "20px"
    },
    jumpLink: {
        textDecoration: "underline",
        cursor: "pointer",
        color: "blue"
    }
}));

export default function Register() {
    const classes = useStyles();
    const navigate = useNavigate();

    // controlling
    const [dataProtection, setDataProtection] = useState<boolean>(false);
    const [accepted, setAccepted] = useState<boolean>(false);
    const [pending, setPending] = useState<boolean>(false);
    const [registered, setRegistered] = useState<boolean>(false);
    // validation
    const [validated, setValidated] = useState<boolean>(false);
    const [usernameExists, setUsernameExists] = useState<boolean>(false);
    const [mailAddressExists, setMailAddressExists] = useState<boolean>(false);
    const [validIban, setValidIban] = useState<boolean>(false);
    const [validBic, setValidBic] = useState<boolean>(true);
    // membership section
    const [username, setUsername] = useState<string>("");
    const [membership, setMembership] = useState<Membership>(Membership.NONE);
    const [mailAddress, setMailAddress] = useState<string>("");
    const [courseMember, setCourseMember] = useState<boolean>(false);
    // personal data section
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [gender, setGender] = useState<Gender>(Gender.NONE);
    const [dateOfBirth, handleDateChange] = useState<Date | null>(null);
    // address section
    const [streetLine, setStreetLine] = useState<string>("");
    const [zipCode, setZipCode] = useState<string>("");
    const [city, setCity] = useState<string>("");
    // contact section
    const [phoneNumber, setPhoneNumber] = useState<string>("");
    const [mobileNumber, setMobileNumber] = useState<string>("");
    // banking account section
    const [accountHolder, setAccountHolder] = useState<string>("");
    const [iban, setIban] = useState<string>("");
    const [bic, setBic] = useState<string>("");
    const [bankName, setBankName] = useState<string>("");

    const handleRegister = (): void => {
        console.info("starting register");
        if (handleValidation()) {
            // do register
            setPending(true);
            RegisterServiceApi.checkUser(username, mailAddress).then(response => {
                const uniqueFieldsAvailable = response.length === 0;
                if (!uniqueFieldsAvailable) {
                    console.info("username or mailAddress not available");
                    setUsernameExists(response.includes(username));
                    setMailAddressExists(response.includes(mailAddress))
                    setPending(false);
                } else {
                    console.info("username or mailAddress available:", uniqueFieldsAvailable);
                    handleSendRegister();
                }
            }).catch((error: ApiError) => {
                if (error.status !== 404) {
                    console.error("unexpected error: " + error.message);
                }
                setPending(false);
                console.info("checkUser DONE");
            });
        }
    }

    const handleSendRegister = (): void => {
        setValidated(false);
        RegisterServiceApi.sendRegister(createRegistrationData()).then(response => {
            console.info("registered successfully", response);
            setRegistered(true);
        }).catch((error: ApiError) => {
            if (error.status !== 404) {
                console.error("unexpected error: " + error.message);
            }
        }).finally(() => {
            setPending(false);
            console.info("register DONE");
        });
    }

    const createRegistrationData = (): RegistrationData => {
        const data: RegistrationData = new RegistrationData(username, membership, mailAddress, courseMember,
            firstName, lastName, gender, dateOfBirth, streetLine, zipCode, city,
            phoneNumber, mobileNumber, accountHolder, iban, bankName);
        data.bic = bic;
        return data;
    }

    const handleValidation = () => {
        setValidated(true);
        setValidIban(validateIban(iban));
        setValidBic(validateBic(bic));
        if (!username || membership === Membership.NONE || !mailAddress
            || !firstName || !lastName || gender === Gender.NONE || !dateOfBirth
            || !streetLine || !zipCode || !city
            || (!phoneNumber && !mobileNumber)
            || !accountHolder || !validIban || !validBic || !bankName
        ) {
            console.info("registration failed. Required fields not filled.");
            return false;
        } else {
            console.info("registration valid.");
            setUsernameExists(false);
            setMailAddressExists(false);
            return true;
        }
    }

    const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDataProtection(event.target.checked);
    }

    return (
        <>
            <PageHeader headerImage="header/register.jpg"
                        headerImagePosition={{positionY: "38%"}}>
                Beim USCO anmelden
            </PageHeader>
            <PageContent>
                {!registered && (<div className={classes.registerContainer}>
                    <RegisterAccount validated={validated}
                                     usernameExists={usernameExists}
                                     mailAddressExists={mailAddressExists}
                                     username={username}
                                     membership={membership}
                                     mailAddress={mailAddress}
                                     courseMember={courseMember}
                                     onChangeUsername={setUsername}
                                     onChangeMembership={setMembership}
                                     onChangeMailAddress={setMailAddress}
                                     onChangeCourseMember={setCourseMember}/>
                    <RegisterPersonalData validated={validated}
                                          firstName={firstName}
                                          lastName={lastName}
                                          gender={gender}
                                          dateOfBirth={dateOfBirth}
                                          onChangeFirstName={setFirstName}
                                          onChangeLastName={setLastName}
                                          onChangeGender={setGender}
                                          onChangeDateOfBirth={handleDateChange}/>
                    <RegisterContact validated={validated}
                                     streetLine={streetLine}
                                     zipCode={zipCode}
                                     city={city}
                                     phoneNumber={phoneNumber}
                                     mobileNumber={mobileNumber}
                                     onChangeStreetline={setStreetLine}
                                     onChangeZipCode={setZipCode}
                                     onChangeCity={setCity}
                                     onChangePhoneNumber={setPhoneNumber}
                                     onChangeMobileNumber={setMobileNumber}/>
                    <RegisterBankAccount validated={validated}
                                         accountHolder={accountHolder}
                                         iban={iban}
                                         ibanValid={validIban}
                                         bicValid={validBic}
                                         bankName={bankName}
                                         onChangeAccountHolder={setAccountHolder}
                                         onChangeIban={(iban: string) => {
                                             if (validated) {
                                                 setValidIban(validateIban(iban));
                                             }
                                             setIban(iban);
                                         }}
                                         onChangeBic={(bic: string) => {
                                             if (validated) {
                                                 setValidBic(validateBic(bic));
                                             }
                                             setBic(bic);
                                         }}
                                         onChangeBankName={setBankName}/>
                    <RegisterDirectDebitMandate accepted={accepted}
                                                onChangeAccepted={setAccepted}/>
                    <ContentCheckbox checked={dataProtection}
                                     multiline
                                     error={validated && !dataProtection}
                                     helperText={validated && !dataProtection ? "Die Erklärung zum Datenschutz sowie " +
                                         "die weiterführenden Datenschutzinformationen wurden nicht akzeptiert." : null}
                                     label={<>Ja, ich habe die <a href={"../data-protection"}
                                                                  target="_blank"
                                                                  rel="noreferrer">
                                     Erklärung zum Datenschutz</a>&nbsp;sowie die weiterführenden&nbsp;
                                         <a href={"../static/dsgvo/Datenschutz-Merkblatt_USCO_F.pdf"}
                                            target="_blank" rel="noreferrer">
                                             Datenschutzinformationen (PDF)</a> des Universitätssegelclub Oldenburg e.V.
                                         (USCO) gemäß DSGVO zur Kenntnis genommen.</>}
                                     onChange={handleCheck}/>
                    <div className={classes.registerInfoText}>
                        Mir ist bewusst, dass ich nur Boote buchen und nutzen darf,
                        wenn ich vorab einen entsprechenden Nachweis über meine
                        Segelkompetenz erbracht habe (z.B. Sportbootführerschein Binnen).
                    </div>
                    <div className={classes.registerButtonContainer}>
                        <ContentButton variant="secondary"
                                       className={classes.registerAbortButton}
                                       onClick={() => navigate(-1)}>Zurück</ContentButton>
                        <ContentLoadingButton className={classes.registerSaveButton}
                                              disabled={!accepted || !dataProtection}
                                              pending={pending}
                                              onClick={handleRegister}>Anmelden</ContentLoadingButton>
                    </div>
                </div>)}
                {registered && (<RegisterSummery/>)}
            </PageContent>
        </>
    );
}
