import React, {useContext, useState} from "react";
import {AuthServiceContext} from "../../../provider/AuthServiceProvider";
import {UserRole} from "../../../model/IUser";
import {DropdownMenu, DropdownMenuItem} from "../../../components/menu/DropdownMenu";
import {DrawerMenu, DrawerMenuItem} from "../../../components/menu/DrawerMenu";
import {useNavigate} from "react-router";
import {makeStyles} from "@mui/styles";
import * as ROUTES from "../../../constants/routes";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";
import '../HeaderMenu.css';

const useStyles = makeStyles(() => ({
    userNameMenuItem: {
        padding: "6px 16px",
        fontSize: "1rem"
    },
    userNameMenuItemSpace: {
        marginTop: "4px",
        marginBottom: "4px"
    },
    loginButton: {
        width: "172px",
    },
    loginMenuLink: {
        width: "172px",
        height: "100%",
        lineHeight: "26px",
        color: "#ffffff",
        fontFamily: "'Roboto Mono', monospace",
        fontSize: "20px",
        textDecoration: "none",
        backgroundColor: "#02B8B7",
        borderRadius: "0",
        "&:hover": {
            backgroundColor: "rgba(2,184,183,0.8)"
        }
    }
}));

export function LoginMenu() {
    const classes = useStyles();
    const navigate = useNavigate();
    const {user, login, logout, editMode, toggleEditMode} = useContext(AuthServiceContext);

    const [open, setOpen] = useState(false);

    const handleLogin = () => {
        login();
    }

    const handleLogout = () => {
        navigate(ROUTES.HOME);
        logout();
    }

    const handleToggleEditMode = () => {
        toggleEditMode();
        setOpen(false);
    }

    return (
        <>
            {(() => {
                if (user.authenticated) {
                    return (
                        <>
                            <DropdownMenu title={<Icon>account_circle</Icon>}
                                          className={"menuLink"}
                                          state={open}
                                          onStateChange={setOpen}>
                                {user.authenticated &&
                                <li className={classes.userNameMenuItem}>{user.username}</li>
                                }
                                {user.authenticated &&
                                <Divider component="div" className={classes.userNameMenuItemSpace}/>
                                }
                                <DropdownMenuItem target={ROUTES.PROFILE}
                                                  title="Profil"
                                                  className="subMenuLink"
                                                  onStateChange={setOpen}/>
                                {user.isInUserRole(UserRole.EDIT_CONTENT) &&
                                <MenuItem onClick={handleToggleEditMode} style={{textTransform: "uppercase"}}>
                                    Schreibmodus {!editMode ? "aktivieren" : "deaktivieren"}</MenuItem>
                                }
                                <DropdownMenuItem target={handleLogout}
                                                  title="Logout"
                                                  className="subMenuLink"
                                                  onStateChange={setOpen}/>
                            </DropdownMenu>
                        </>
                    )
                } else {
                    return (
                        <Button variant={"text"}
                                className={classes.loginMenuLink}
                                onClick={handleLogin}><Icon style={{ fontSize: "20px", marginRight: "10px" }}>login</Icon>Login</Button>
                    )
                }
            })()}
        </>
    );
}

export function LoginDrawerMenu() {
    const navigate = useNavigate();
    const {user, login, logout, editMode, toggleEditMode} = useContext(AuthServiceContext);

    const handleLogin = () => {
        login();
    }

    const handleLogout = () => {
        navigate(ROUTES.HOME);
        logout();
    }

    const handleToggleEditMode = () => {
        toggleEditMode();
    }

    return (
        <>
            {user.authenticated && (
                <DrawerMenu title={`${user.firstName} ${user.lastName}`}>
                    <DrawerMenuItem target={ROUTES.PROFILE} title="Profile" className="subMenuLink" />
                    {user.isInUserRole(UserRole.EDIT_CONTENT) &&
                        <DrawerMenuItem target={handleToggleEditMode}
                                        className="subMenuLink"
                                        title={`Schreibmodus ${!editMode ? "aktivieren": "deaktivieren"}`} />
                    }
                    <DrawerMenuItem target={handleLogout} title="Logout" className="subMenuLink" />
                </DrawerMenu>
            )}
            {!user.authenticated && (
                <DrawerMenu divider={false}>
                    <DrawerMenuItem itemStyle={{ backgroundColor: "#02A1A0", color: "#ffffff" }}
                                    target={handleLogin}
                                    className="subMenuLink"
                                    title="Login" />
                </DrawerMenu>
            )}
        </>
    );
}
